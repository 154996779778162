body {
	height: 100%;
	color: #222;
	font-family: "Open Sans", sans-serif;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
}

h1 {
	color: #629900;
	margin-top: 1rem;
	margin-bottom: 1rem;
	font-size: 2rem;
	font-weight: 600;
	line-height: 2.5rem;
}

h2 {
	color: #3169b4;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	font-size: 1.4rem;
	font-weight: 700;
	line-height: 1.9rem;
}

h3 {
	color: #3169b4;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	font-size: 1.2rem;
	font-weight: 700;
	line-height: 1.5rem;
}

h4 {
	color: #3169b4;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	font-size: 1.1rem;
	font-weight: 700;
	line-height: 1.6rem;
}

h5 {
	color: #3169b4;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	font-size: 1rem;
	font-weight: 700;
	line-height: 1.5rem;
}

h6 {
	color: #3169b4;
	text-transform: uppercase;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	font-size: 0.9rem;
	font-weight: 700;
	line-height: 1.2rem;
}

p {
	margin-top: 10px;
}

a {
	color: #000;
	font-weight: 600;
	text-decoration: none;
	transition: all 0.2s;
}

a:hover {
	color: #aaa;
	text-decoration: underline;
}

ul {
	margin-top: 0.25rem;
	margin-bottom: 0;
	padding-left: 30px;
}

ol {
	margin-top: 0.25rem;
	margin-bottom: 0.75rem;
	padding-left: 30px;
}

label {
	color: #000;
	margin-bottom: 5px;
	font-weight: 700;
	display: block;
}

blockquote {
	border-left: 5px solid #3169b4;
	margin-bottom: 10px;
	padding: 10px 20px;
	font-size: 1.15rem;
	line-height: 1.75rem;
}

.button {
	color: #fff;
	text-align: center;
	text-transform: capitalize;
	background-color: #3169b4;
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	margin-right: 20px;
	padding: 0.375rem 1rem;
	font-family: Montserrat, sans-serif;
	font-size: 0.95rem;
	font-weight: 600;
	text-decoration: none;
	transition: background-color 0.2s, color 0.2s;
	display: inline-block;
}

.button:hover {
	color: #fff;
	background-color: #525252;
	text-decoration: none;
}

.button.header-search-button {
	width: 25%;
	height: 50px;
	margin-top: 0;
	margin-bottom: 0;
	margin-right: 0;
	padding-left: 0;
	padding-right: 0;
}

.button.secondary-button {
	background-color: #797676;
}

.button.secondary-button:hover {
	background-color: #525252;
}

.nav-section {
	z-index: 99;
	box-shadow: none;
	background-color: #fff;
	border-bottom: 4px solid #02205c;
	justify-content: space-between;
	display: flex;
	position: fixed;
	top: 0%;
	bottom: auto;
	left: 0%;
	right: 0%;
}

.nav-logo-block {
	width: 228px;
	margin-top: 1.6rem;
	margin-bottom: 0.5rem;
	margin-left: 2rem;
	text-decoration: none;
}

.nav-logo-block.w--current {
	display: flex;
}

.nav-menu {
	float: none;
	border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.nav-link {
	color: #565656;
	letter-spacing: -0.02rem;
	text-transform: uppercase;
	margin-bottom: 0;
	margin-left: 1px;
	margin-right: 1px;
	padding: 0.75rem 0.7rem 20px;
	font-family: Open Sans, sans-serif;
	font-size: 0.9rem;
	font-weight: 600;
	line-height: 1.3rem;
	transition: all 0.2s;
}

.nav-link:hover {
	color: #000;
	padding-bottom: 0.4rem;
	text-decoration: none;
}

.nav-link.w--current {
	color: #565656;
	font-weight: 600;
	text-decoration: none;
}

.nav-link.w--current:hover {
	color: #000;
	background-color: rgba(0, 0, 0, 0);
	border-bottom-style: none;
}

.nav-link.w--open {
	border-bottom-color: #71a01e;
}

.nav-link.w--open:hover {
	text-decoration: none;
}

.nav-link.dropdown-toggle {
	text-align: center;
}

.nav-link.mobile-duplicate {
	border-bottom-width: 0;
	border-bottom-color: silver;
	padding-left: 0.73rem;
	padding-right: 0.73rem;
}

.nav-link.mobile-duplicate:hover {
	border-bottom-color: silver;
}

.nav-link.mobile-duplicate.w--current {
	color: #ee0008;
	font-weight: 600;
}

.nav-dropdown {
	font-weight: 600;
	display: none;
}

.nav-dropdown-icon {
	width: 1rem;
	height: 1rem;
}

.nav-menu-button {
	position: relative;
}

.nav-dropdown-list {
	background-color: #fff;
	padding: 0.625rem 0.75rem 0.75rem;
	display: none;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
}

.nav-dropdown-list.w--open {
	background-color: #fff;
	margin-top: 1px;
	padding-left: 0.25rem;
	padding-right: 0.25rem;
	display: none;
}

.nav-dropdown-link {
	border-top: 1px rgba(0, 0, 0, 0.15);
	padding: 0.5rem;
	font-size: 0.9rem;
	text-decoration: none;
}

.nav-dropdown-link:hover {
	color: #fff;
	background-color: gray;
	text-decoration: none;
}

.nav-dropdown-link.last-dropdown-link {
	border-bottom: 1px rgba(0, 0, 0, 0.15);
}

.page-section {
	display: block;
	position: relative;
	top: 140px;
}

.page-section.footer-section {
	color: #233237;
	overflow: hidden;
	background-color: #2d2d2d;
	padding-top: 1rem;
}

.page-section.content-box-section-1 {
	box-shadow: none;
	background-color: #969696;
	background-image: linear-gradient(rgba(150, 150, 150, 0.3), rgba(150, 150, 150, 0.3));
	background-position: 0 0;
	background-size: auto;
	top: 140px;
}

.page-section.content-box-section-1.inside-1 {
	background-image: url('/20150114134215/assets/images/intersection-2.jpg');
	background-position: 0 0;
	background-size: auto;
}

.page-section.content-box-section-1.inside-2 {
	background-image: url('/20150114134215/assets/images/intersection-2.jpg'), linear-gradient(rgba(150, 150, 150, 0.3), rgba(150, 150, 150, 0.3));
	background-position: 0 0, 0 0;
	background-size: auto, auto;
}

.page-section.content-box-section-1.inside-3 {
	background-image: url('/20150114134215/assets/images/intersection-2.jpg');
	background-position: 0 0;
	background-size: auto;
}

.page-section.content-box-section-1.inside-4 {
	background-image: url('/20150114134215/assets/images/intersection-2.jpg'), linear-gradient(rgba(150, 150, 150, 0.3), rgba(150, 150, 150, 0.3));
	background-position: 0 0, 0 0;
	background-size: auto, auto;
}

.page-section.no-move {
	top: 0;
}

.column {
	padding-bottom: 2.5rem;
	padding-left: 10px;
	padding-right: 10px;
}

.column.footer-column {
	padding-bottom: 1rem;
	display: flex;
}

.column.left-signoff-column {
	padding-bottom: 0;
	padding-left: 20px;
}

.column.center-signoff-column {
	text-align: center;
	padding-bottom: 0;
}

.column.row-1-column {
	background-color: rgba(0, 0, 0, 0);
}

.breadcrumbs-list {
	margin-top: 0;
	margin-bottom: 1rem;
	font-family: Montserrat, sans-serif;
	font-weight: 600;
}

.breadcrumbs-list-item {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	margin-right: 0.5rem;
	font-size: 0.8rem;
	line-height: 1.4rem;
	display: inline-block;
}

.breadcrumbs-list-divider {
	margin-left: 0.5rem;
	display: inline-block;
}

.split-50 {
	width: 80%;
	min-width: 0;
	float: left;
}

.inside-page-row {
	min-height: 500px;
	padding-top: 1.5rem;
	padding-bottom: 2rem;
}

.inside-page-left-column {
	padding-top: 1.25rem;
	display: block;
}

.inside-page-content-column {
	padding-top: 0;
	padding-left: 0;
	font-family: Montserrat, sans-serif;
}

.left-nav-list {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
}

.left-nav-list.nested-left-nav-list {
	margin-top: 2px;
	margin-bottom: 0;
	margin-left: 20px;
	padding-bottom: 0;
	box-shadow: 0 -1px rgba(115, 115, 115, 0.15);
}

.left-nav-list-item {
	margin-top: 1px;
	box-shadow: 0 1px #e0e0e0;
}

.left-nav-list-item.nested-left-nav-list-item {
	box-shadow: 0 1px #e0e0e0;
}

.left-nav-list-item.nested-left-nav-list-item.last-left-nav-list-item {
	box-shadow: none;
}

.left-nav-list-item-link {
	color: #222;
	padding: 0.5rem 0.5rem 0.5rem 1.125rem;
	font-family: Montserrat, sans-serif;
	font-size: 0.93rem;
	font-weight: 600;
	line-height: 1.33rem;
	text-decoration: none;
	transition: color 0.2s, background-color 0.2s;
	display: block;
}

.left-nav-list-item-link:hover {
	color: gray;
	background-image: url('/20150114134215/assets/images/angle-right.svg');
	background-position: 0;
	background-repeat: no-repeat;
	background-size: 16px 16px;
	text-decoration: none;
}

.left-nav-list-item-link.w--current,
.left-nav-list-item-link.active,
.left-nav-list-item-link.nested-left-nav-link.active,
.left-nav-list-item-link.nested-left-nav-link.active:hover {
	color: #3169b4;
	background-image: url('/20150114134215/assets/images/angle-right.svg');
	background-position: 0;
	background-repeat: no-repeat;
	background-size: 16px 16px;
}

.left-nav-list-item-link.w--current:hover {
	text-decoration: none;
}

.left-nav-list-item-link.nested-left-nav-link {
	color: #363636;
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.left-nav-list-item-link.nested-left-nav-link:hover {
	color: silver;
	background-image: none;
	text-decoration: none;
}

.inside-page-nested-row {
	padding-top: 0.5rem;
}

.inside-page-nested-right-column {
	padding-top: 1rem;
}

.inside-page-right-column-heading {
	color: #222;
	margin-top: 0;
	font-size: 1rem;
	line-height: 1.5rem;
}

.inside-page-right-col-item-wrap {
	border-top: 2px solid #3169b4;
	margin-bottom: 1rem;
	margin-left: 20px;
	padding: 0.75rem 0.5rem 0.25rem;
}

.column-content-wrap {
	margin-bottom: 2rem;
	margin-left: 10px;
	margin-right: 10px;
}

.column-content-wrap.social-media-wrap {
	text-align: center;
	border-bottom: 1px solid gray;
	margin-bottom: 1rem;
	padding-bottom: 0.5rem;
}

.column-content-wrap.navigation-column {
	padding-right: 15px;
}

.footer-signoff-row {
	padding-bottom: 0.5rem;
	display: flex;
}

.footer-grip-link {
	width: 130px;
	height: 28px;
	min-width: 0;
	float: right;
	color: #555;
	text-transform: uppercase;
	background-image: url('/20150114134215/assets/images/grip_logo_white-webdevelopment.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 130px 28px;
	padding-left: 32px;
	font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
	font-size: 8px;
	line-height: 0.625rem;
	text-decoration: none;
}

.footer-grip-link:hover {
	color: #555;
	text-decoration: none;
}

.footer-signoff-text {
	color: #e9e9e9;
	text-align: center;
	font-family: Montserrat, sans-serif;
	display: inline-block;
}

.footer-signoff-text h1 {
	display: inline;
	padding: 0px;
	margin-top: 0px;
	line-height: inherit;
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
	font-family: inherit;
}

.footer-signoff-link {
	color: #fff;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	font-weight: 400;
	text-decoration: none;
	display: inline-block;
}

.footer-signoff-link:hover {
	color: #b1cd80;
	text-decoration: none;
}

.footer-signoff-link-wrap {
	margin-top: 0.25rem;
	margin-left: 1rem;
	margin-right: 1rem;
	display: block;
}

.footer-list-item {
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.footer-social-icon-link {
	width: 1.75rem;
	height: 1.75rem;
	min-width: 0;
	float: none;
	background-color: #797676;
	margin: 0.5rem 0.5rem 0.25rem;
	transition: all 0.2s;
}

.footer-social-icon-link:hover {
	background-color: #629900;
}

.footer-social-icon-image {
	float: left;
}

.header-container {
	flex-direction: column;
	justify-content: flex-end;
	display: flex;
}

.header-container.number {
	justify-content: flex-end;
}

.header-search-wrap {
	width: 500px;
	background-color: #d3d3d3;
	align-items: center;
	position: absolute;
	top: 100%;
	right: 0;
	overflow: hidden;
}

.header-search-form {
	display: flex;
}

.header-search-field {
	width: 75%;
	height: 50px;
	border: 0 solid #000;
	border-radius: 0;
	margin-bottom: 0;
	margin-right: 0%;
	display: inline-block;
}

.header-search-field:focus {
	border-color: rgba(0, 0, 0, 0);
	box-shadow: inset 0 0 #000;
}

.header-search-session-message {
	width: 100%;
	height: 2px;
	max-height: 2px;
	max-width: 100%;
	background-color: rgba(0, 0, 0, 0);
	margin-top: 0;
	padding: 0;
}

.footer-column-title {
	color: #fff;
	text-transform: capitalize;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	font-family: Montserrat, sans-serif;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.625rem;
}

.left-nav-wrap {
	margin-top: 0;
	padding-right: 15px;
}

.footer-list-link {
	color: #fff;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	font-family: Open Sans, sans-serif;
	font-size: 0.95rem;
	font-weight: 400;
	display: block;
}

.footer-list-link:hover {
	color: #b1cd80;
	text-decoration: none;
}

.footer-list-link.w--current {
	color: #fff;
	text-decoration: underline;
}

.header-nav-bar-content-wrap {
	float: right;
	flex-direction: row;
	justify-content: flex-end;
	align-items: flex-end;
	display: flex;
}

.header-nav-bar-content-wrap.menu {
	flex-direction: column;
	align-items: flex-end;
}

.header-search-form-wrap {
	margin-bottom: 0;
	padding: 1rem;
	display: none;
	position: relative;
}

.footer-signoff-bar {
	background-color: #000;
	margin-top: 0.5rem;
	padding-top: 1rem;
	padding-bottom: 0;
	font-size: 0.75rem;
	line-height: 1.25rem;
}

.content-box-title {
	color: #02205c;
	letter-spacing: -0.03rem;
	margin-top: 0.5rem;
	margin-bottom: 1.5rem;
	font-family: Montserrat, sans-serif;
	font-size: 1.75rem;
	font-weight: 600;
	line-height: 2.3rem;
}

.wide-background {
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
	padding: 2rem 10px;
	display: block;
	position: relative;
}

.wide-background.wide-background-row-1 {
	max-width: 1300px;
	background-color: rgba(255, 255, 255, 0.1);
	padding-top: 2.25rem;
	padding-bottom: 0;
}

.wide-background.wide-background-inside-page {
	background-color: #fff;
	padding: 0 40px;
}

.logo-by-line {
	color: #888;
}

.news-abstract {
	margin-bottom: 1rem;
	font-family: Montserrat, sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
}

.news-feed-article-heading {
	margin-top: 0;
	margin-bottom: 0.25rem;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.5rem;
}

.news-feed-date {
	color: #474848;
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	font-family: Montserrat, sans-serif;
	font-size: 0.8rem;
	font-weight: 400;
	line-height: 1.1rem;
}

.footer-map-link {
	color: #fff;
	background-image: url('/20150114134215/assets/images/fontawesome-map-pin-white.svg');
	background-position: 0;
	background-repeat: no-repeat;
	background-size: 18px 18px;
	padding-left: 25px;
	font-size: 0.95rem;
	font-weight: 400;
}

.footer-map-link:hover {
	color: #969696;
	text-decoration: none;
}

.footer-paragraph {
	color: #fff;
	font-family: Montserrat, sans-serif;
	font-weight: 400;
}

.footer-paragraph.fax-text {
	background-image: url('/20150114134215/assets/images/printer-fax-white.svg');
	background-position: 0;
	background-repeat: no-repeat;
	background-size: 18px 18px;
	padding-left: 28px;
}

.email-link {
	color: #fff;
	background-image: url('/20150114134215/assets/images/envelope-white.svg');
	background-position: 0;
	background-repeat: no-repeat;
	background-size: 18px 18px;
	padding-left: 28px;
	font-weight: 400;
	display: block;
}

.email-link:hover {
	color: #969696;
	text-decoration: none;
}

.phone-link {
	color: #fff;
	background-image: url('/20150114134215/assets/images/fontawesome-phone-white.svg');
	background-position: 0;
	background-repeat: no-repeat;
	background-size: 18px 18px;
	padding-left: 28px;
	font-weight: 400;
}

.phone-link:hover {
	color: #969696;
	text-decoration: none;
}

.social-icons-wrap.social-icons-wrap-header {
	float: right;
	margin-bottom: 0.25rem;
	margin-right: 0;
	display: none;
}

.newsletter-signup-button {
	height: 28px;
	float: right;
	background-color: #02205c;
	border-left: 1px #000;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	padding: 2px 1.5rem 2px 1rem;
	font-family: Montserrat, sans-serif;
	font-size: 0.85rem;
	font-weight: 600;
	transition: all 0.2s;
	display: inline-block;
}

.newsletter-signup-button:hover {
	color: #fff;
	background-color: #525252;
	padding-right: 24px;
	text-decoration: none;
}

.newsletter-div {
	float: right;
	text-align: left;
	margin-left: 1.5rem;
	margin-right: 0.5rem;
	position: relative;
}

.slider-widget {
	height: 550px;
	background-color: rgba(0, 0, 0, 0);
	top: 140px;
}

.slider-mask {
	height: 100%;
}

.slide-1 {
	background-image: none;
}

.slider-caption-div {
	max-height: 298px;
	max-width: 45%;
	min-height: 128px;
	min-width: 45%;
	float: right;
	background-color: rgba(255, 255, 255, 0.88);
	padding: 1.5rem 2.25rem 3rem;
	position: relative;
	top: 50%;
	bottom: 0;
	right: 0;
	transform: translate(0, -50%);
}

.slider-header {
	color: #02205c;
	letter-spacing: -0.03rem;
	margin-top: 0;
	font-family: Montserrat, sans-serif;
	line-height: 2.8rem;
	position: static;
}

.slider-container {
	height: 100%;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
}

.slider-button {
	text-transform: uppercase;
	background-color: #ed1c24;
	font-weight: 600;
	display: none;
	position: relative;
	left: 50%;
	transform: translate(-50%);
}

.more-text-arrow-link {
	color: #02205c;
	background-image: url('/20150114134215/assets/images/angle-right.svg');
	background-position: 100%;
	background-repeat: no-repeat;
	background-size: 16px 16px;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	margin-left: 10px;
	padding-right: 1rem;
	font-family: Montserrat, sans-serif;
	font-size: 0.92rem;
	font-weight: 700;
	display: inline-block;
	position: absolute;
	bottom: 0;
	text-transform: uppercase;
	left: 0;
}

.more-text-arrow-link:hover {
	color: #3169b4;
	text-decoration: none;
}

.more-text-arrow-link.more-text-arrow-link-slider {
	float: right;
	color: #02205c;
	margin-top: 0;
	margin-bottom: 1.5rem;
	font-family: Montserrat, sans-serif;
	font-weight: 700;
	bottom: 0;
	left: auto;
	right: 10%;
	text-transform: uppercase;
}

.more-text-arrow-link.more-text-arrow-link-slider:hover {
	color: #3169b4;
	text-decoration: none;
}

.more-text-arrow-link.more-row-1 {
	color: #02205c;
	margin-bottom: 1rem;
	padding-right: 1.125rem;
	font-family: Montserrat, sans-serif;
	font-size: 0.92rem;
	font-weight: 700;
	left: 10px;
}

.more-text-arrow-link.more-row-1:hover {
	color: #3169b4;
	text-decoration: none;
}

.slider-arrow-link-bar {
	height: 40px;
}

.slider-arrow-link-bar:hover {
	opacity: 0.7;
}

.slider-arrow-link-bar.slide-arrow-left {
	font-size: 28px;
	top: auto;
	bottom: 0;
	left: auto;
}

.slider-arrow-link-bar.slide-arrow-right {
	font-size: 28px;
	top: auto;
	left: auto;
}

.content-box-paragraph {
	clear: both;
	font-family: Montserrat, sans-serif;
	font-size: 1rem;
}

.slide-nav {
	background-color: rgba(255, 255, 255, 0.2);
	padding-top: 13px;
	font-size: 0.8rem;
}

.slider-image-div-block {
	height: 100%;
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
}

.slider-image-div-block.slide-1-image {
	background-size: cover;
}

.slider-image-div-block.slide-2-image {
	background-position: 50% 0;
	background-size: cover;
}

.slider-image-div-block.slide-3-image {
	background-position: 50% 0;
	background-size: cover;
}

.slider-caption-paragraph {
	max-height: 120px;
	font-family: Montserrat, sans-serif;
}

.right-signoff-column {
	padding-right: 20px;
}

.back-to-top-div {
	color: #fff;
	text-align: center;
	background-color: #131212;
	border-top: 1px solid #363636;
	border-bottom: 1px gray;
	margin-top: 1rem;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
}

.back-to-top-link {
	color: #fff;
	background-image: url('/20150114134215/assets/images/arrow-medium-up-white.svg');
	background-position: 100%;
	background-repeat: no-repeat;
	background-size: 14px;
	padding-right: 26px;
	font-size: 1rem;
	font-weight: 400;
	display: inline;
}

.back-to-top-link:hover {
	opacity: 1;
	color: #b1cd80;
	background-image: url('/20150114134215/assets/images/arrow-medium-up-white.svg');
	background-position: 100%;
	background-repeat: no-repeat;
	background-size: 14px;
	text-decoration: none;
}

.row-1-column {
	background-color: #fff;
	padding-bottom: 2.5rem;
}

.colored-content-box {
	background-color: #fff;
	border-top: 2px solid #02205c;
	padding: 0.5rem 20px 2.75rem;
	position: relative;
	width: 100%;
}

.no-sidebars-wrap {
	padding: 1.5rem 120px 2rem;
	font-family: Montserrat, sans-serif;
}

.field-label {
	color: #000;
	font-size: 0.95rem;
}

.text-field {
	font-family: Montserrat, sans-serif;
	font-size: 0.87rem;
}

.session-text {
	color: #000;
	text-align: left;
	font-weight: 600;
}

.session-text.error-message {
	background-color: #fcc;
	padding: 20px;
}

.session-text.success-message {
	background-color: #9eeb98;
}

.breadcrumb-link {
	color: #797676;
	font-weight: 400;
	text-decoration: underline;
	transition: all 0.2s;
	position: static;
}

.breadcrumb-link:hover {
	color: #aaa;
	text-decoration: none;
}

.breadcrumb-link.w--current:hover {
	text-decoration: none;
}

.text-link {
	color: #02205c;
	border-bottom: 1px solid silver;
	font-family: Montserrat, sans-serif;
	transition: all 0.2s;
}

.text-link:hover {
	color: #3169b4;
	border-bottom-color: rgba(0, 0, 0, 0);
	text-decoration: none;
}

.current-page {
	color: #797676;
	font-weight: 400;
}

.h1-link {
	color: #02205c;
	border-bottom: 1px solid silver;
	transition: all 0.2s;
}

.h1-link:hover {
	color: #474848;
	border-bottom-color: rgba(0, 0, 0, 0);
	text-decoration: none;
}

.heading-link {
	color: #3169b4;
	border-bottom: 1px solid #3169b4;
	font-weight: 700;
}

.heading-link:hover {
	color: #474848;
	border-bottom-color: rgba(0, 0, 0, 0);
	text-decoration: none;
}

.dropdown-list {
	padding-top: 6px;
	display: none;
}

.dropdown-list.w--open {
	background-color: #fff;
	padding: 6px;
	box-shadow: -2px 2px 4px rgba(41, 41, 41, 0.2), 2px 2px 4px rgba(41, 41, 41, 0.2);
}

.dropdown-link {
	color: #222;
	background-color: rgba(0, 0, 0, 0);
	margin-top: 1px;
	padding-left: 10px;
	padding-right: 10px;
	font-family: Open Sans, sans-serif;
	font-size: 0.9rem;
	font-weight: 400;
}

.dropdown-link:hover {
	color: #fff;
	background-color: #3169b4;
	text-decoration: none;
}

.dropdown-link.w--current {
	color: #fff;
	background-color: #3169b4;
	margin-bottom: 0;
}

.main-nav-dd {
	border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.nav-link-dd-toggle {
	color: #565656;
	text-transform: uppercase;
	margin-left: 0;
	margin-right: 0;
	padding: 0 0 0 0;
	font-size: 0.9rem;
	font-weight: 600;
	line-height: 1.3rem;
	transition: all 0.2s;
}

.nav-link-dd-toggle:hover {
	border-bottom: 6px solid #02205c;
	padding-bottom: 0;
}

.nav-link-dd-toggle.w--current {
	color: #565656;
	border-bottom-color: #8bc426;
	font-weight: 600;
	text-decoration: none;
}

.nav-link-dd-toggle.w--current:hover {
	color: #000;
	background-color: rgba(0, 0, 0, 0);
	border-bottom-color: #4484c3;
}

.nav-link-dd-toggle.w--open {
	color: #000;
	border-bottom: 6px solid #02205c;
}

.nav-link-dd-toggle.w--open:hover {
	text-decoration: none;
}

.nav-link-no-subpages {
	color: #565656;
	text-transform: uppercase;
	border-bottom: 6px solid rgba(237, 28, 36, 0);
	margin-left: 1px;
	margin-right: 1px;
	padding: 0.75rem 0.5rem 0.4rem;
	font-size: 0.9rem;
	font-weight: 600;
	line-height: 1.3rem;
	transition: all 0.2s;
}

.nav-link-no-subpages:hover {
	color: #000;
	background-color: rgba(0, 0, 0, 0);
	border-bottom: 6px solid #71a01e;
	padding-bottom: 0.4rem;
	text-decoration: none;
}

.nav-link-no-subpages.w--current {
	color: #000;
	border-bottom-color: #02205c;
	font-family: Montserrat, sans-serif;
	font-weight: 600;
	text-decoration: none;
}

.nav-link-no-subpages.w--current:hover {
	color: #000;
	background-color: rgba(0, 0, 0, 0);
	border-bottom-color: #02205c;
}

.nav-link-no-subpages.w--open {
	border-bottom-color: #71a01e;
}

.nav-link-no-subpages.w--open:hover {
	text-decoration: none;
}

.unordered-list-2 {
	box-shadow: 0 -2px #3169b4;
}

.acrobat-reader-link {
	color: #fff;
	margin-bottom: 1rem;
	margin-left: 0;
	margin-right: 0.5rem;
	font-family: Open Sans, sans-serif;
	font-size: 0.85rem;
	font-weight: 400;
	line-height: 1.5rem;
	text-decoration: none;
	display: inline-block;
}

.acrobat-reader-link:hover {
	color: #b1cd80;
	text-decoration: none;
}

.ecommerce-links {
	float: right;
	margin-top: 15px;
	margin-right: 0.25rem;
	display: none;
}

.ecommercelinkimg {
	width: 1em;
	height: 1em;
	float: left;
	opacity: 0.35;
	margin-right: 0.5em;
}

.ecommercelinktext {
	float: left;
	text-transform: uppercase;
	margin-top: 1px;
	font-size: 0.825rem;
	font-weight: 600;
	line-height: 0.825rem;
}

.ecommerce-link-block {
	color: #565656;
	margin-left: 1.5rem;
	transition-duration: 0.4s;
}

.ecommerce-link-block:hover {
	color: #000;
}

.top-nav-wrap {
	float: right;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	display: flex;
}

.logo-image {
	width: 60%;
}

.screen-message-wrapper {
	background-color: #fff;
	border: 2px solid #000;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
}

.screen-message-wrapper.success {
	background-color: #bbee94;
	border-width: 2px;
	border-color: #48a700;
}

.screen-message-wrapper.error {
	background-color: #ffccd0;
	border-color: #ff0014;
}

.screen-message {
	font-family: Montserrat, sans-serif;
}

.right-side-col-wrapper {
	padding-left: 1.25rem;
	font-family: Montserrat, sans-serif;
}

.right-side-col-wrapper h4 {
	color: #222;
	border-top: 2px solid #3169b4;
	margin-top: 1.25rem;
	padding-top: 0.75rem;
	font-size: 1rem;
	line-height: 1.5rem;
}

.right-side-col-wrapper h2 {
	color: #222;
	border-top: 2px solid #3169b4;
	margin-top: 0;
	padding-top: 0.75rem;
	font-size: 1rem;
	line-height: 1.5rem;
}

.right-side-col-wrapper h3 {
	color: #222;
	border-top: 2px solid #3169b4;
	margin-top: 1.25rem;
	padding-top: 0.75rem;
	font-size: 1rem;
}

.right-side-col-wrapper p {
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.image {
	width: 100%;
}

.fake-image-placeholder {
	width: 100%;
	margin-bottom: 1.5rem;
}

.newsletter-signup-button-2 {
	height: 28px;
	float: right;
	background-color: #02205c;
	border-left: 1px #000;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	padding: 2px 1.5rem 2px 1rem;
	font-family: Open Sans, sans-serif;
	font-size: 0.85rem;
	font-weight: 600;
	transition: all 0.2s;
	display: inline-block;
}

.newsletter-signup-button-2:hover {
	color: #fff;
	background-color: #525252;
	padding-right: 24px;
	text-decoration: none;
}

.nav-link-dd-toggle-2 {
	color: #565656;
	text-transform: uppercase;
	border-bottom: 6px solid rgba(0, 0, 0, 0);
	margin-left: 0;
	margin-right: 0;
	padding: 0 0 0 0;
	font-size: 0.9rem;
	font-weight: 600;
	line-height: 1.3rem;
	transition: all 0.2s;
}

.nav-link-dd-toggle-2:hover {
	border-bottom: 6px solid #02205c;
	padding-bottom: 0;
}

.nav-link-dd-toggle-2.w--current {
	color: #565656;
	border-bottom-color: #8bc426;
	font-weight: 600;
	text-decoration: none;
}

.nav-link-dd-toggle-2.w--current:hover {
	color: #000;
	background-color: rgba(0, 0, 0, 0);
	border-bottom-color: #4484c3;
}

.nav-link-dd-toggle-2.w--open {
	color: #000;
	border-bottom: 6px solid #02205c;
}

.nav-link-dd-toggle-2.w--open:hover {
	text-decoration: none;
}

.logo-image-2 {
	width: 80%;
	padding-bottom: 5px;
}

.nav-link-no-subpages-2 {
	color: #565656;
	text-transform: uppercase;
	border-bottom: 6px solid rgba(237, 28, 36, 0);
	margin-left: 1px;
	margin-right: 1px;
	padding: 0.75rem 0.5rem 0.4rem;
	font-size: 0.9rem;
	font-weight: 600;
	line-height: 1.3rem;
	transition: all 0.2s;
}

.nav-link-no-subpages-2:hover {
	color: #000;
	background-color: rgba(0, 0, 0, 0);
	border-bottom: 6px solid #71a01e;
	padding-bottom: 0.4rem;
	text-decoration: none;
}

.nav-link-no-subpages-2.w--current {
	color: #000;
	border-bottom-color: #71a01e;
	font-weight: 600;
	text-decoration: none;
}

.nav-link-no-subpages-2.w--current:hover {
	color: #000;
	background-color: rgba(0, 0, 0, 0);
	border-bottom-color: #71a01e;
}

.nav-link-no-subpages-2.w--open {
	border-bottom-color: #71a01e;
}

.nav-link-no-subpages-2.w--open:hover {
	text-decoration: none;
}

.nav-link-no-subpages-2.hidden {
	display: block;
}

.section {
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	display: flex;
}

.div-block {
	width: 100%;
	max-width: 1000px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	display: flex;
}

.header-container-2 {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	grid-template-rows: 0.25fr 1fr;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	align-content: stretch;
	align-items: stretch;
	display: grid;
	position: static;
}

.nav-logo-block-2 {
	width: 228px;
	margin-top: 0.5rem;
	margin-bottom: 0;
	margin-left: 10px;
	text-decoration: none;
}

.header-nav-bar-content-wrap-2,
.top-nav-wrap-2 {
	float: right;
}

.ecommerce-links-2 {
	width: 236px;
	float: right;
	margin-top: 15px;
	margin-right: 0.25rem;
	display: block;
}

.newsletter-signup-button-3 {
	height: 28px;
	float: right;
	background-color: #02205c;
	border-left: 1px #000;
	margin-top: 0;
	margin-bottom: 0.5rem;
	padding: 2px 10px;
	font-family: Montserrat, sans-serif;
	font-size: 16px;
	font-weight: 600;
	transition: all 0.2s;
	display: inline-block;
}

.newsletter-signup-button-3:hover {
	color: #fff;
	background-color: #02205c;
	padding-left: 10px;
	padding-right: 10px;
	text-decoration: none;
}

.nav-link-2 {
	color: #565656;
	letter-spacing: -0.02rem;
	text-transform: uppercase;
	margin-left: 1px;
	margin-right: 1px;
	padding: 0.75rem 0.7rem 0.4rem;
	font-family: Montserrat, sans-serif;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.3rem;
	transition: all 0.2s;
	padding-bottom: 0.45rem;
}

.nav-link-2:hover {
	color: #000;
	padding-bottom: 0.4rem;
	text-decoration: none;
}

.nav-link-2.w--current {
	color: #565656;
	font-weight: 600;
	text-decoration: none;
}

.nav-link-2.w--current:hover {
	color: #000;
	background-color: rgba(0, 0, 0, 0);
	border-bottom-style: none;
}

.nav-link-2.w--open {
	border-bottom-color: #71a01e;
}

.nav-link-2.w--open:hover {
	text-decoration: none;
}

.nav-link-2.dropdown-toggle {
	text-align: center;
}

.nav-link-2.mobile-duplicate {
	border-bottom-width: 0;
	border-bottom-color: silver;
	padding-left: 0.73rem;
	padding-right: 0.73rem;
}

.nav-link-2.mobile-duplicate:hover {
	border-bottom-color: silver;
}

.nav-link-2.mobile-duplicate.w--current {
	color: #ee0008;
	font-weight: 600;
}

.heading,
.heading-2,
.heading-3,
.heading-4 {
	color: #02205c;
	font-family: Montserrat, sans-serif;
}

.heading-5,
.heading-6 {
	color: #02205c;
}

.utility-page-wrap {
	width: 100vw;
	height: 100vh;
	max-height: 100%;
	max-width: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
}

.utility-page-content {
	width: 260px;
	text-align: center;
	flex-direction: column;
	display: flex;
}

.utility-page-form {
	flex-direction: column;
	align-items: stretch;
	display: flex;
}

.heading-7,
.div-block-2,
.heading-8,
.paragraph,
.heading-9,
.paragraph-2,
.list,
.paragraph-3,
.heading-10,
.paragraph-4,
.list-2,
.heading-11,
.paragraph-5,
.block-quote,
.paragraph-6,
.heading-12,
.paragraph-7,
.paragraph-8,
.heading-13,
.heading-14,
.html-embed,
.heading-15 {
	font-family: Montserrat, sans-serif;
}

html.w-mod-js [data-ix="mobile-dropdown"] {
	display: none;
}

@media screen and (max-width: 991px) {
	.nav-logo-block {
		width: 200px;
		margin-top: 1rem;
		margin-bottom: 0.75rem;
		margin-left: 2.88rem;
	}

	.nav-menu {
		width: 100%;
		background-color: #3169b4;
		border-top: 3px solid #000;
		border-bottom: 1px solid rgba(0, 0, 0, 0.25);
		margin-top: 160px;
		margin-bottom: 0;
		padding-top: 0;
		padding-bottom: 5px;
	}

	.nav-link {
		width: 100%;
		height: 50px;
		color: #fff;
		margin-bottom: 1px;
		padding-left: 1.25rem;
		font-size: 1rem;
		line-height: 1.5rem;
		box-shadow: 0 1px rgba(0, 0, 0, 0.25);
	}

	.nav-link:hover {
		box-shadow: none;
		color: #fff;
		background-color: #222;
		border-bottom-style: solid;
		border-bottom-color: rgba(237, 28, 36, 0);
	}

	.nav-link.w--current {
		color: #fff;
		background-color: #000;
		border-bottom-style: none;
		font-size: 1rem;
		line-height: 1.5rem;
		box-shadow: 0 1px rgba(0, 0, 0, 0.2);
	}

	.nav-link.w--current:hover {
		color: #fff;
		background-color: rgba(0, 0, 0, 0.35);
	}

	.nav-link.dropdown-toggle {
		width: 20%;
		float: right;
	}

	.nav-link.mobile-duplicate {
		background-image: url('/20150114134215/assets/images/icon_arrow2_right_wht.svg');
		background-position: 96%;
		background-repeat: no-repeat;
		background-size: 20px;
		padding-left: 1.25rem;
		box-shadow: 0 1px rgba(0, 0, 0, 0.25);
	}

	.nav-link.mobile-duplicate:hover {
		border-bottom-color: rgba(238, 0, 8, 0);
	}

	.nav-menu-button {
		width: 4.5rem;
		height: 5rem;
		float: none;
		text-align: center;
		background-color: rgba(0, 0, 0, 0);
		margin-left: 1rem;
		padding: 0;
	}

	.nav-menu-button:hover {
		color: #fff;
		background-color: #02205c;
	}

	.nav-menu-button.w--open {
		background-color: #02205c;
	}

	.nav-dropdown-list {
		float: left;
		box-shadow: none;
		background-color: gray;
		margin-bottom: 0.75rem;
		padding: 0;
		display: none;
	}

	.nav-dropdown-list.w--open {
		background-color: rgba(0, 0, 0, 0.2);
		border-top: 2px solid #000;
		border-bottom: 1px solid rgba(0, 0, 0, 0.25);
		padding: 0 0 5px;
	}

	.nav-dropdown-link {
		color: #fff;
		border-top: 0 rgba(0, 0, 0, 0);
		border-bottom: 1px solid rgba(0, 0, 0, 0.09);
		padding: 0.75rem 1rem;
	}

	.nav-dropdown-link:hover {
		background-color: rgba(0, 0, 0, 0.6);
		text-decoration: none;
	}

	.page-section.footer-section {
		padding-top: 1rem;
	}

	.container.container-inside-page {
		padding-left: 10px;
		padding-right: 10px;
	}

	.cb-row.cb-row-1 {
		margin-bottom: 1rem;
	}

	.column.footer-column {
		padding-bottom: 1rem;
	}

	.column.left-signoff-column {
		text-align: center;
		padding-top: 0.75rem;
		padding-bottom: 0.25rem;
		padding-right: 20px;
	}

	.column.center-signoff-column {
		padding-bottom: 0.25rem;
	}

	.column.row-1-column {
		padding-bottom: 1rem;
		padding-left: 5px;
		padding-right: 5px;
	}

	.inside-page-row {
		padding-top: 0.25rem;
	}

	.inside-page-left-column {
		display: none;
	}

	.inside-page-content-column {
		padding-right: 0;
	}

	.inside-page-nested-row {
		padding-top: 0.5rem;
	}

	.inside-page-nested-content-column {
		margin-bottom: 1rem;
		padding-bottom: 0;
	}

	.inside-page-right-col-item-wrap {
		margin-bottom: 0;
		margin-left: 0;
	}

	.column-content-wrap.social-media-wrap {
		margin-left: 0;
		margin-right: 0;
	}

	.column-content-wrap.navigation-column {
		padding-right: 30%;
	}

	.footer-grip-link {
		float: none;
	}

	.footer-signoff-link-wrap {
		margin-top: 0.5rem;
		margin-bottom: 1rem;
	}

	.header-container {
		max-width: 100%;
		justify-content: space-between;
		margin-left: auto;
		margin-right: auto;
		display: flex;
	}

	.header-search-wrap {
		min-width: 100%;
		margin-bottom: 0;
	}

	.nav-menu-button-icon {
		margin-top: 1rem;
		font-size: 2rem;
		line-height: 2rem;
	}

	.nav-menu-button-text {
		text-transform: uppercase;
		font-size: 0.875rem;
		font-weight: 600;
		line-height: 1rem;
	}

	.content-box-title {
		margin-bottom: 0.5rem;
		font-size: 1.5rem;
		line-height: 2rem;
	}

	.wide-background {
		padding-top: 1.75rem;
		padding-bottom: 2rem;
	}

	.wide-background.wide-background-row-1 {
		padding-top: 2rem;
	}

	.wide-background.wide-background-inside-page {
		max-width: 991px;
		margin-left: 1rem;
		margin-right: 1rem;
		padding-bottom: 0;
		padding-left: 30px;
		padding-right: 30px;
	}

	.news-abstract,
	.news-feed-article-heading {
		font-size: 0.95rem;
		line-height: 1.45rem;
	}

	.social-icons-wrap.social-icons-wrap-header {
		margin-top: 0.75rem;
		margin-right: 0;
		display: none;
	}

	.newsletter-signup-button {
		float: none;
		background-color: gray;
		background-image: none;
	}

	.newsletter-div {
		margin-top: 0.75rem;
		margin-left: 0;
		margin-right: 0;
	}

	.slider-widget {
		height: 500px;
	}

	.slider-caption-div {
		max-width: 50%;
		padding: 1.25rem 1.5rem 3rem;
	}

	.slider-header {
		margin-top: 0;
		font-size: 1.75rem;
		line-height: 2.15rem;
	}

	.slider-container {
		position: absolute;
	}

	.more-text-arrow-link.more-text-arrow-link-slider {
		right: 8%;
	}

	.more-text-arrow-link.more-row-1 {
		left: 5px;
	}

	.content-box-paragraph {
		line-height: 1.45rem;
	}

	.slider-image-div-block.slide-2-image {
		background-image: url('/20150114134215/assets/images/Slider-2b.jpg');
		background-position: 28%;
		background-size: cover;
	}

	.right-signoff-column {
		text-align: center;
		padding-right: 10px;
	}

	.back-to-top-link {
		background-size: auto 14px;
	}

	.colored-content-box {
		padding-left: 15px;
		padding-right: 15px;
	}

	.no-sidebars-wrap {
		margin-left: -10px;
		margin-right: -10px;
		padding-top: 0.25rem;
		padding-left: 0;
		padding-right: 0;
	}

	.main-nav-dd {
		display: block;
	}

	.nav-link-dd-toggle {
		width: 100%;
		height: 50px;
		color: #fff;
		margin-bottom: 1px;
		padding-left: 1.25rem;
		font-size: 1rem;
		line-height: 1.5rem;
		box-shadow: 0 1px rgba(0, 0, 0, 0.25);
	}

	.nav-link-dd-toggle:hover {
		box-shadow: none;
		color: #fff;
		background-color: #222;
		border-bottom-style: solid;
		border-bottom-color: rgba(237, 28, 36, 0);
	}

	.nav-link-dd-toggle.w--current {
		color: #fff;
		background-color: #000;
		border-bottom-style: none;
		font-size: 1rem;
		line-height: 1.5rem;
		box-shadow: 0 1px rgba(0, 0, 0, 0.2);
	}

	.nav-link-dd-toggle.w--current:hover {
		color: #fff;
		background-color: rgba(0, 0, 0, 0.35);
	}

	.nav-link-no-subpages {
		width: 100%;
		height: 50px;
		color: #fff;
		margin-bottom: 1px;
		padding-left: 1.25rem;
		font-size: 1rem;
		line-height: 1.5rem;
		box-shadow: 0 1px rgba(0, 0, 0, 0.25);
	}

	.nav-link-no-subpages:hover {
		box-shadow: none;
		color: #fff;
		background-color: #222;
		border-bottom-style: solid;
		border-bottom-color: rgba(237, 28, 36, 0);
	}

	.nav-link-no-subpages.w--current {
		color: #fff;
		background-color: #000;
		border-bottom-style: none;
		font-size: 1rem;
		line-height: 1.5rem;
		box-shadow: 0 1px rgba(0, 0, 0, 0.2);
	}

	.nav-link-no-subpages.w--current:hover {
		color: #fff;
		background-color: rgba(0, 0, 0, 0.35);
	}

	.acrobat-reader-link {
		text-align: center;
		margin-right: 0;
		font-size: 0.85rem;
	}

	.ecommerce-links {
		float: none;
		margin-top: 1.5rem;
		display: block;
	}

	.ecommercelinkimg {
		width: 1.5em;
		height: 1.5em;
		float: none;
		margin-right: 0;
	}

	.ecommercelinkimg:hover {
		opacity: 0.9;
	}

	.ecommercelinktext {
		display: none;
	}

	.top-nav-wrap {
		flex-direction: row-reverse;
		flex: 1;
		display: flex;
	}

	.right-side-col-wrapper {
		padding-left: 0;
	}

	.newsletter-signup-button-2 {
		float: none;
		background-color: gray;
		background-image: none;
	}

	.nav-link-dd-toggle-2 {
		width: 100%;
		height: 50px;
		color: #fff;
		margin-bottom: 1px;
		padding-left: 1.25rem;
		font-size: 1rem;
		line-height: 1.5rem;
		box-shadow: 0 1px rgba(0, 0, 0, 0.25);
	}

	.nav-link-dd-toggle-2:hover {
		box-shadow: none;
		color: #fff;
		background-color: #222;
		border-bottom-style: solid;
		border-bottom-color: rgba(237, 28, 36, 0);
	}

	.nav-link-dd-toggle-2.w--current {
		color: #fff;
		background-color: #000;
		border-bottom-style: none;
		font-size: 1rem;
		line-height: 1.5rem;
		box-shadow: 0 1px rgba(0, 0, 0, 0.2);
	}

	.nav-link-dd-toggle-2.w--current:hover {
		color: #fff;
		background-color: rgba(0, 0, 0, 0.35);
	}

	.nav-link-no-subpages-2 {
		width: 100%;
		height: 50px;
		color: #fff;
		margin-bottom: 1px;
		padding-left: 1.25rem;
		font-size: 1rem;
		line-height: 1.5rem;
		box-shadow: 0 1px rgba(0, 0, 0, 0.25);
	}

	.nav-link-no-subpages-2:hover {
		box-shadow: none;
		color: #fff;
		background-color: #222;
		border-bottom-style: solid;
		border-bottom-color: rgba(237, 28, 36, 0);
	}

	.nav-link-no-subpages-2.w--current {
		color: #fff;
		background-color: #000;
		border-bottom-style: none;
		font-size: 1rem;
		line-height: 1.5rem;
		box-shadow: 0 1px rgba(0, 0, 0, 0.2);
	}

	.nav-link-no-subpages-2.w--current:hover {
		color: #fff;
		background-color: rgba(0, 0, 0, 0.35);
	}

	.header-container-2 {
		max-width: 760px;
		justify-content: space-between;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		width: 100%;
	}

	.nav-logo-block-2 {
		width: 200px;
		margin-top: 1rem;
		margin-bottom: 0.75rem;
		margin-left: 2.88rem;
	}

	.top-nav-wrap-2 {
		flex-direction: row-reverse;
		flex: 1;
		align-items: center;
		display: flex;
	}

	.ecommerce-links-2 {
		float: none;
		margin-top: 1.5rem;
		display: block;
	}

	.newsletter-signup-button-3 {
		float: none;
		background-color: #02205c;
		background-image: none;
		font-size: 14px;
	}

	.nav-link-2 {
		width: 100%;
		height: 50px;
		color: #fff;
		margin-bottom: 1px;
		padding-left: 1.25rem;
		font-size: 1rem;
		line-height: 1.5rem;
		box-shadow: 0 1px rgba(0, 0, 0, 0.25);
	}

	.nav-link-2:hover {
		box-shadow: none;
		color: #fff;
		background-color: #222;
		border-bottom-style: solid;
		border-bottom-color: rgba(237, 28, 36, 0);
	}

	.nav-link-2.w--current {
		color: #fff;
		background-color: #000;
		border-bottom-style: none;
		font-size: 1rem;
		line-height: 1.5rem;
		box-shadow: 0 1px rgba(0, 0, 0, 0.2);
	}

	.nav-link-2.w--current:hover {
		color: #fff;
		background-color: rgba(0, 0, 0, 0.35);
	}

	.nav-link-2.dropdown-toggle {
		width: 20%;
		float: right;
	}

	.nav-link-2.mobile-duplicate {
		background-image: url('/20150114134215/assets/images/icon_arrow2_right_wht.svg');
		background-position: 96%;
		background-repeat: no-repeat;
		background-size: 20px;
		padding-left: 1.25rem;
		box-shadow: 0 1px rgba(0, 0, 0, 0.25);
	}

	.nav-link-2.mobile-duplicate:hover {
		border-bottom-color: rgba(238, 0, 8, 0);
	}
}

@media screen and (max-width: 767px) {
	h1 {
		margin-bottom: 0.75rem;
		font-size: 1.75rem;
		line-height: 2.25rem;
	}

	h2 {
		font-size: 1.3rem;
		line-height: 1.8rem;
	}

	ul,
	ol {
		padding-left: 20px;
	}

	.nav-logo-block {
		width: 180px;
		margin-top: 1rem;
		margin-left: 2.25rem;
	}

	.nav-menu-button.w--open {
		background-color: #02205c;
	}

	.container {
		padding-left: 10px;
		padding-right: 10px;
	}

	.cb-row.cb-row-1 {
		border-bottom: 1px #000;
		margin-bottom: 0;
		padding-bottom: 0;
	}

	.column {
		border-bottom: 1px solid silver;
		padding: 0.75rem 20px 3.5rem;
	}

	.column.footer-column {
		border-bottom: 1px #000;
		padding-left: 10px;
		padding-right: 10px;
	}

	.column.left-signoff-column {
		border-bottom: 1px #000;
		padding-bottom: 0.25rem;
	}

	.column.center-signoff-column {
		border-bottom: 1px #000;
		padding-top: 0;
		padding-bottom: 0.25rem;
	}

	.column.row-1-column {
		border-bottom: 1px #000;
	}

	.inside-page-nested-content-column,
	.inside-page-nested-right-column {
		padding-left: 0;
		padding-right: 0;
	}

	.column-content-wrap.navigation-column {
		padding-right: 15%;
	}

	.footer-signoff-row {
		text-align: center;
	}

	.footer-grip-link {
		float: none;
	}

	.footer-signoff-text {
		display: block;
	}

	.footer-signoff-link-wrap {
		margin-top: 0.5rem;
		margin-bottom: 1rem;
		display: block;
	}

	.footer-social-icon-link {
		margin-left: 0;
		margin-right: 1rem;
	}

	.wide-background {
		box-shadow: none;
		padding-top: 0.5rem;
		padding-left: 0;
		padding-right: 0;
	}

	.wide-background.wide-background-row-1 {
		box-shadow: none;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.wide-background.wide-background-inside-page {
		max-width: 767px;
		padding-left: 10px;
		padding-right: 10px;
	}

	.news-abstract,
	.news-feed-article-heading {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.social-icons-wrap.social-icons-wrap-header {
		float: left;
		margin-left: 0.25rem;
	}

	.newsletter-div {
		float: left;
		clear: left;
		margin-left: 20px;
	}

	.slider-widget {
		min-height: 460px;
		height: auto;
	}

	.slide-1 {
		height: 100%;
	}

	.slider-caption-div {
		width: 100%;
		height: 240px;
		max-height: none;
		max-width: 100%;
		min-height: 0;
		min-width: 0%;
		float: left;
		background-color: #fff;
		margin-left: auto;
		margin-right: auto;
		display: block;
		position: absolute;
		top: auto;
		bottom: 0;
		left: 0;
		right: 0;
		transform: none;
	}

	.slider-header {
		font-size: 1.75rem;
		line-height: 2.25rem;
	}

	.more-text-arrow-link {
		margin-bottom: 1.5rem;
		margin-left: 20px;
	}

	.more-text-arrow-link.more-row-1 {
		left: -5px;
	}

	.slider-arrow-link-bar.slide-arrow-left {
		bottom: 0;
		left: 0;
	}

	.content-box-paragraph {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.slide-nav {
		display: block;
		top: 33px;
		left: 0;
		right: 0;
	}

	.slide-nav.slider-caption-paragraph {
		font-size: 0.8rem;
		top: -58px;
	}

	.slider-image-div-block {
		height: 300px;
	}

	.slider-caption-paragraph {
		font-size: 1rem;
	}

	.no-sidebars-wrap {
		padding-left: 10px;
		padding-right: 10px;
	}

	.nav-logo-block-2 {
		width: 180px;
		margin-top: 1rem;
		margin-left: 2.25rem;
	}

	.header-container-2 {
		max-width: 608px;
	}
}

@media screen and (max-width: 479px) {
	h1 {
		font-size: 1.65rem;
		line-height: 2rem;
	}

	h2 {
		font-size: 1.2rem;
		line-height: 1.7rem;
	}

	h6,
	p {
		color: #000;
	}

	ul {
		padding-left: 20px;
	}

	blockquote {
		padding-left: 15px;
		font-size: 1rem;
		line-height: 1.6rem;
	}

	.nav-logo-block {
		width: 180px;
		float: none;
		margin: 0.75rem auto;
		padding-left: 0;
		display: block;
	}

	.nav-menu {
		background-color: #3169b4;
		margin-top: 149px;
	}

	.nav-link,
	.nav-link.w--current {
		font-size: 0.95rem;
	}

	.nav-menu-button {
		width: 50px;
		height: 50px;
		float: none;
	}

	.nav-menu-button.w--open {
		background-color: #02205c;
	}

	.column {
		border-bottom-width: 1px;
		border-bottom-color: silver;
		padding-left: 10px;
		padding-right: 10px;
	}

	.column.footer-column {
		flex-direction: column;
		padding-top: 0.25rem;
	}

	.column.left-signoff-column {
		border-bottom: 1px #000;
	}

	.column.center-signoff-column {
		border-bottom: 1px #000;
		padding-top: 0;
	}

	.split-50 {
		width: 100%;
		flex-direction: row;
		margin-bottom: 1rem;
		display: flex;
	}

	.column-content-wrap {
		margin-bottom: 1rem;
	}

	.column-content-wrap.navigation-column {
		padding-right: 0%;
	}

	.footer-signoff-row {
		flex-direction: column;
	}

	.header-container {
		width: 100%;
		height: 117px;
		flex-direction: column;
	}

	.header-search-wrap {
		width: 100%;
	}

	.header-search-form {
		justify-content: flex-end;
	}

	.nav-menu-button-icon {
		margin-top: 0.5rem;
	}

	.nav-menu-button-text {
		display: none;
	}

	.footer-signoff-bar {
		margin-top: 0;
	}

	.wide-background.wide-background-inside-page {
		max-width: 479px;
		margin-left: 0.75rem;
		margin-right: 0.75rem;
	}

	.social-icons-wrap.social-icons-wrap-header {
		float: none;
		margin-bottom: 0;
	}

	.newsletter-div {
		float: none;
		margin-top: 0;
		margin-left: 0.75rem;
	}

	.slider-widget {
		min-height: 506px;
		top: 122px;
	}

	.slide-2 {
		background-image: none;
	}

	.slider-caption-div {
		height: 286px;
		padding-bottom: 3rem;
		padding-left: 20px;
		padding-right: 20px;
	}

	.slider-header {
		font-size: 1.5rem;
		line-height: 2rem;
	}

	.more-text-arrow-link {
		margin-left: 10px;
	}

	.more-text-arrow-link.more-row-1 {
		left: 5px;
	}

	.content-box-paragraph {
		font-size: 0.95rem;
		line-height: 1.45rem;
	}

	.slide-nav {
		top: -92px;
		left: 0;
		right: 0;
	}

	.slide-nav.slider-caption-paragraph {
		font-size: 0.7rem;
		top: -105px;
	}

	.slider-image-div-block {
		height: 220px;
	}

	.slider-image-div-block.slide-2-image {
		background-image: url('/20150114134215/assets/images/Slider-2b.jpg');
		background-position: 21%;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.slider-caption-paragraph {
		max-height: 144px;
	}

	.no-sidebars-wrap {
		padding-left: 10px;
		padding-right: 10px;
	}

	.nav-link-dd-toggle,
	.nav-link-dd-toggle.w--current,
	.nav-link-no-subpages,
	.nav-link-no-subpages.w--current {
		font-size: 0.95rem;
	}

	.ecommerce-links {
		float: none;
		align-self: center;
		margin-top: 0;
	}

	.ecommercelinkimg,
	.ecommercelinktext {
		float: none;
	}

	.top-nav-wrap {
		width: 100%;
		float: none;
		border-top: 1px solid #d3d3d3;
		flex-direction: row-reverse;
		justify-content: flex-start;
		align-items: center;
		display: flex;
		position: relative;
	}

	.nav-link-dd-toggle-2,
	.nav-link-dd-toggle-2.w--current {
		font-size: 0.95rem;
	}

	.logo-image-2 {
		text-align: left;
	}

	.nav-link-no-subpages-2,
	.nav-link-no-subpages-2.w--current {
		font-size: 0.95rem;
	}

	.header-container-2 {
		width: 100%;
		height: 120px;
		flex-direction: column;
	}

	.nav-logo-block-2 {
		width: 180px;
		float: none;
		justify-content: center;
		margin: 0 auto 0.75rem;
		padding-left: 0;
		display: flex;
	}

	.top-nav-wrap-2 {
		width: 100%;
		float: none;
		border-top: 1px solid #d3d3d3;
		flex-direction: row-reverse;
		justify-content: flex-start;
		align-items: center;
		display: flex;
		position: absolute;
		top: 0%;
		bottom: 0%;
		left: auto;
		right: 0%;
	}

	.ecommerce-links-2 {
		float: none;
		align-self: center;
		margin-top: 0;
	}

	.nav-link-2,
	.nav-link-2.w--current {
		font-size: 0.95rem;
	}
}

#w-node-a4dba20f-c1db-45bd-ae8a-bdc49b0e23f4-39111c97 {
	grid-area: 1 / 1 / 3 / 2;
	justify-self: auto;
}

#w-node-a4dba20f-c1db-45bd-ae8a-bdc49b0e23f6-39111c97 {
	align-self: auto;
}

#w-node-a4dba20f-c1db-45bd-ae8a-bdc49b0e2410-39111c97 {
	grid-area: 2 / 2 / 3 / 3;
	align-self: end;
	justify-self: end;
}

#w-node-ede36456-b396-49fa-d75e-591f0dd92298-9f111c9d {
	grid-area: 1 / 1 / 3 / 2;
	justify-self: auto;
}

#w-node-ede36456-b396-49fa-d75e-591f0dd9229a-9f111c9d {
	align-self: auto;
}

#w-node-ede36456-b396-49fa-d75e-591f0dd922ac-9f111c9d {
	grid-area: 2 / 2 / 3 / 3;
	align-self: end;
	justify-self: end;
}

#w-node-_3b35f3b5-5e66-47f6-f026-2207381cecb2-56111c9e {
	grid-area: 1 / 1 / 3 / 2;
	justify-self: auto;
}

#w-node-_3b35f3b5-5e66-47f6-f026-2207381cecb4-56111c9e {
	align-self: auto;
}

#w-node-_3b35f3b5-5e66-47f6-f026-2207381cecc6-56111c9e {
	grid-area: 2 / 2 / 3 / 3;
	align-self: end;
	justify-self: end;
}

#w-node-eaa69cd3-347e-5ed5-3f5a-7bb828793d7a-b2111c9f {
	grid-area: 1 / 1 / 3 / 2;
	justify-self: auto;
}

#w-node-eaa69cd3-347e-5ed5-3f5a-7bb828793d7c-b2111c9f {
	align-self: auto;
}

#w-node-eaa69cd3-347e-5ed5-3f5a-7bb828793d8e-b2111c9f {
	grid-area: 2 / 2 / 3 / 3;
	align-self: end;
	justify-self: end;
}

#w-node-_4a46554f-fcb9-873a-934d-f61f27fdce9f-be111ca1 {
	grid-area: 1 / 1 / 3 / 2;
	justify-self: auto;
}

#w-node-_4a46554f-fcb9-873a-934d-f61f27fdcea1-be111ca1 {
	align-self: auto;
}

#w-node-_4a46554f-fcb9-873a-934d-f61f27fdceb3-be111ca1 {
	grid-area: 2 / 2 / 3 / 3;
	align-self: end;
	justify-self: end;
}

#w-node-_434e91f8-7844-f59b-634c-31cc05ccbbd5-14111caa {
	grid-area: 1 / 1 / 3 / 2;
	justify-self: auto;
}

#w-node-_434e91f8-7844-f59b-634c-31cc05ccbbd7-14111caa {
	align-self: auto;
}

#w-node-_434e91f8-7844-f59b-634c-31cc05ccbbe9-14111caa {
	grid-area: 2 / 2 / 3 / 3;
	align-self: end;
	justify-self: end;
}

/******************** Webflow ***********************/
/****************************************************/
body {
	text-rendering: optimizeLegibility;
}

@media screen and (min-width: 992px) {
	.w-container {
		max-width: 1200px;
	}

	.header-container-2 {
		width: 100%;
		max-width: 832px;
	}
}

@media all and (max-width: 1300px) {
	.slider-arrow-link-bar {
		display: none;
	}
}

@media screen and (min-width: 768px) {
	.row-1-column {
		display: flex;
		overflow: hidden;
		align-items: stretch;
	}
}

@media screen and (min-width: 768px) {
	.cb-row {
		display: flex;
		overflow: hidden;
		align-items: stretch;
	}
}

/****************** Tablesaw ***********************/
/****************************************************/
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and (min-width: 991px) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and (min-width: 767px) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/***************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Header **********************/
/*****************************************************/

/****************** Accesible menu **********************/
/*****************************************************/
.accesible-navigation-menu ul {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
	display: flex;
}

.accesible-navigation-menu li.nav-link-dd-toggle-2 {
	position: relative;
}

.accesible-navigation-menu li.nav-link-dd-toggle-2 .nav-link-2 {
	display: inline-block;
	margin-right: 5px;
}

.accesible-navigation-menu li.nav-link-dd-toggle-2:last-child .nav-link-2 {
	margin-right: 1px;
}

.accesible-navigation-menu li.nav-link-dd-toggle-2 .container-wrapper {
	opacity: 0;
	max-height: 0px;
	position: absolute;
	top: 100%;
	z-index: 100;
	left: auto;
	transition: max-height 100ms ease 0s, opacity 200ms ease 0s;
	height: 0;
	width: 100%;
	padding-left: 0px;
}

.accesible-navigation-menu li.nav-link-dd-toggle-2 .container-wrapper ul {
	display: none;
	background-color: #fff;
	padding: 6px;
	box-shadow: -2px 2px 4px rgba(41, 41, 41, 0.2), 2px 2px 4px rgba(41, 41, 41, 0.2);
	margin: 0px;
	list-style-type: none;
	width: 100%;
	margin-top: 8px;
	min-width: 200px;
}

.accesible-navigation-menu li.nav-link-dd-toggle-2 .container-wrapper ul li {
	display: flex;
	width: 100%;
}

.accesible-navigation-menu li.nav-link-dd-toggle-2 .container-wrapper ul a {
	color: #222;
	background-color: rgba(0, 0, 0, 0);
	margin-top: 1px;
	padding-left: 10px;
	padding-right: 10px;
	font-family: Open Sans, sans-serif;
	font-size: 0.9rem;
	font-weight: 400;
	display: inline-block;
	width: 100%;
	padding: 11px 20px 9px 20px;
	text-decoration: none;
	border-bottom: 1px solid transparent;
	word-wrap: break-word;
}

.accesible-navigation-menu li.nav-link-dd-toggle-2 .container-wrapper ul a:hover,
.accesible-navigation-menu li.nav-link-dd-toggle-2 .container-wrapper ul a.w--current {
	color: #fff;
	background-color: #3169b4;
}

.accesible-navigation-menu li.nav-link-dd-toggle-2.open .container-wrapper,
.accesible-navigation-menu li.nav-link-dd-toggle-2:hover .container-wrapper {
	opacity: 1;
	max-height: 500px;
	height: auto;
}

.accesible-navigation-menu li.nav-link-dd-toggle-2.open .container-wrapper ul,
.accesible-navigation-menu li.nav-link-dd-toggle-2:hover .container-wrapper ul {
	display: inline-block;
}

@media (max-width: 991px) {
	.accesible-navigation-menu {
		display: none !important;
	}
}

/****************** Mobile menu **********************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu,
	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu,
	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		transform: translate3d(0%, 0, 0) !important;
	}

	.mobile-navigation-menu {
		transform: translate3d(100%, 0, 0) !important;
	}
}

@media screen and (min-width: 991px) {
	.mobile-navigation-menu li[data-breakpoints*="xl"] {
		display: flex !important;
	}
}

@media screen and (min-width: 767px) {
	.mobile-navigation-menu li[data-breakpoints*="md,lg"] {
		display: flex !important;
	}
}

.mm-menu_offcanvas,
.mobile-navigation-menu {
	width: 100%;
	min-width: 100%;
	margin-top: 100px;
	max-width: 100%;
	background-color: #3169b4;
	z-index: 1000;
	margin-top: 146px;
	transition: transform 0.3s ease;
}

.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened ~ .mm-slideout,
.mm-wrapper_opening .mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
	transform: translate3d(0px, 0, 0) !important;
	transition: transform 0.2s ease;
}

.mobile-navigation-menu li[data-breakpoints] {
	display: none !important;
}

.mobile-navigation-menu li[data-breakpoints*="xs,sm"] {
	display: flex !important;
}

.mobile-navigation-menu > .mm-panels > .mm-panel:first-child > .mm-navbar {
	display: none;
}

@media (max-width: 767px) {
	.mm-menu_offcanvas,
	.mobile-navigation-menu {
		margin-top: 135px;
	}
}

@media (max-width: 479px) {
	.mm-menu_offcanvas,
	.mobile-navigation-menu {
		margin-top: 124px;
	}
}

.mm-panels > .mm-panel,
.mm-navbar {
	background-color: #3169b4;
}

.mm-listitem:after {
	left: 0px;
	border-color: rgba(0, 0, 0, 0.25);
}

.mm-listitem__btn {
	border-color: rgba(0, 0, 0, 0.25);
}

.mm-btn_next:after,
.mm-btn_prev:before {
	border-color: #fff;
}

.mobile-navigation-menu li:hover {
	background-color: #222;
}

.mobile-navigation-menu li a.nav-a {
	letter-spacing: -0.02rem;
	text-transform: uppercase;
	margin-left: 1px;
	margin-right: 1px;
	padding: 12px 20px;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 600;
}

.mobile-navigation-menu li a.nav-a:hover,
.mobile-navigation-menu li a.nav-a.active {
	background-color: #222;
}

.mm-navbar__title > span {
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 600;
	padding-top: 2px;
	text-transform: uppercase;
}

.mm-navbar > * {
	justify-content: flex-start;
}

/********************* Homepage *********************/
/*****************************************************/
.cbtext p {
	color: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-family: inherit;
	font-size: inherit !important;
}

/*********************** Slideshow ********************/
/*****************************************************/
.slider-text p {
	color: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-family: inherit;
	font-size: inherit;
}

.slider-header {
	margin-bottom: 1rem;
	font-size: 2rem;
	font-weight: 600;
}

@media screen and (max-width: 991px) {
	.slider-header {
		margin-top: 0;
		font-size: 1.75rem;
		line-height: 2.15rem;
	}
}

@media screen and (max-width: 767px) {
	.slider-header {
		font-size: 1.75rem;
		line-height: 2.25rem;
	}

	.slider-mask,
	.w-slide {
		height: auto;
		min-height: 460px;
	}

	.slider-image-div-block {
		width: 100%;
	}

	.slider-container,
	.slider-caption-div {
		position: static;
		height: auto;
		margin-top: -40px;
	}

	.slider-caption-paragraph {
		max-height: none;
	}
}

@media screen and (max-width: 479px) {
	.slider-header {
		font-size: 1.5rem;
		line-height: 2rem;
	}

	.slider-container,
	.slider-caption-div {
		margin-top: 0px;
	}

	.slider-mask,
	.w-slide {
		min-height: 506px;
	}
}

/****************** Inside Page ******************/
/*************************************************/
@media screen and (max-width: 991px) {
	iframe[src*="youtube.com"] {
		aspect-ratio: 16 / 9;
		height: auto;
		max-width: 100%;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

.breadcrumbs-list li:last-child .breadcrumbs-list-divider {
	display: none;
}

.breadcrumbs-list li:last-child a {
	color: #797676;
	font-weight: 400;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.only-right .inside-page-row {
	margin-left: 0px;
	margin-right: 0px;
}

.only-right .inside-page-content-column {
	padding-left: 0px;
	padding-right: 0px;
}

.only-right .inside-page-nested-row {
	margin-left: -10px;
	margin-right: -10px;
}

.only-right .inside-page-nested-content-column {
	padding-left: 10px;
	padding-right: 10px;
}

.only-right .inside-page-nested-right-column {
	padding-left: 10px;
	padding-right: 10px;
}

.no-columns .inside-page-row {
	padding: 1.5rem 120px 2rem;
	margin-left: 0px;
	margin-right: 0px;
	font-family: Montserrat, sans-serif;
}

@media screen and (max-width: 991px) {
	.no-columns .inside-page-row {
		margin-left: -10px;
		margin-right: -10px;
		padding-top: 0.25rem;
		padding-left: 0;
		padding-right: 0;
	}
}

@media screen and (max-width: 767px) {
	.no-columns .inside-page-row {
		padding-left: 10px;
		padding-right: 10px;
	}
}

/*************** Right Sidebar ********************/
/*************************************************/
.inside-page-nested-right-column td img {
	max-width: none;
}

.inside-page-nested-right-column .box {
	margin-left: 1.25rem;
	font-family: Montserrat, sans-serif;
	border-top: 2px solid #3169b4;
	margin-bottom: 1.25rem;
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.inside-page-nested-right-column .box h4,
.inside-page-nested-right-column .box h3 {
	padding: 0px;
	margin-bottom: 0.5rem;
	font-weight: 700;
	color: #222;
	font-size: 1rem;
	line-height: 1.5rem;
	margin-top: 0.75rem;
}

.inside-page-nested-right-column .box .content p {
	font-size: 0.9rem;
	font-family: Montserrat, sans-serif;
	line-height: 1.4rem;
}

.inside-page-nested-right-column .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.inside-page-nested-right-column .box .contentbox_item_image {
	width: 24px;
}

.inside-page-nested-right-column .box.documents td:first-child {
	padding-right: 7px;
}

.inside-page-nested-right-column .box.tools td:first-child img {
	margin-right: 5px;
}

.inside-page-nested-right-column a {
	color: #02205c;
	border-bottom: 1px solid silver;
	font-family: Montserrat, sans-serif;
	transition: all 0.2s;
}

.inside-page-nested-right-column a:hover {
	color: #3169b4;
	border-bottom-color: rgba(0, 0, 0, 0);
	text-decoration: none;
}

@media screen and (max-width: 991px) {
	.inside-page-nested-right-column .box {
		margin-left: 0;
	}
}

/****************** Inside Page Styles ***************/
/*****************************************************/
.left-sidebar a,
.inside-page-nested-content-column a,
.cbtext a {
	color: #02205c;
	border-bottom: 1px solid silver;
	font-family: Montserrat, sans-serif;
	transition: all 0.2s;
}

.left-sidebar a:hover,
.inside-page-nested-content-column a:hover,
.cbtext a {
	color: #3169b4;
	border-bottom-color: rgba(0, 0, 0, 0);
	text-decoration: none;
}

h1 {
	color: #02205c;
}

.inside-page-row h1 a,
.cbtext h1 a,
h1 a,
.inside-page-nested-content-column h1 a {
	color: #02205c;
	border-bottom: 1px solid silver;
	transition: all 0.2s;
}

.inside-page-row h2 a,
.cbtext h2 a,
h2 a,
.inside-page-nested-content-column h2 a {
	color: #3169b4;
	border-bottom: 1px solid #3169b4;
	font-weight: 700;
}

.inside-page-row h3 a,
.cbtext h3 a,
h3 a,
.inside-page-nested-content-column h3 a {
	color: #3169b4;
	border-bottom: 1px solid #3169b4;
	font-weight: 700;
}

.inside-page-row h4 a,
.cbtext h4 a,
h4 a,
.inside-page-nested-content-column h4 a {
	color: #3169b4;
	border-bottom: 1px solid #3169b4;
	font-weight: 700;
}

.inside-page-row h5 a,
.cbtext h5 a,
h5 a,
.inside-page-nested-content-column h5 a {
	color: #3169b4;
	border-bottom: 1px solid #3169b4;
	font-weight: 700;
}

.inside-page-row h6 a,
.cbtext h6 a,
h6 a,
.inside-page-nested-content-column h6 a {
	color: #3169b4;
	border-bottom: 1px solid #3169b4;
	font-weight: 700;
}

h1 a:hover,
.inside-page-nested-content-column h1 a:hover {
	color: #474848;
	border-bottom-color: rgba(0, 0, 0, 0);
	text-decoration: none;
}

h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
.inside-page-nested-content-column h2 a:hover,
.inside-page-nested-content-column h3 a:hover,
.inside-page-nested-content-column h4 a:hover,
.inside-page-nested-content-column h5 a:hover,
.inside-page-nested-content-column h6 a:hover {
	color: #474848;
	border-bottom-color: rgba(0, 0, 0, 0);
	text-decoration: none;
}

/* buttons */
.inside-page-row .button,
.inside-page-row a.primary,
.homeboxes a.primary,
.primary,
.inside-page-row button[type="submit"],
.inside-page-row .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
.ui-form-buttons .ui-form-button button,
.module-search button {
	display: inline-block;
	color: #fff !important;
	text-align: center;
	text-transform: capitalize;
	background-color: #3169b4 !important;
	padding: 0.375rem 1rem;
	font-family: Montserrat, sans-serif;
	font-size: 0.95rem;
	font-weight: 600;
	text-decoration: none;
	transition: background-color 0.2s, color 0.2s;
	border: 0;
	line-height: inherit;
	cursor: pointer;
	border-radius: 0;
}

.inside-page-row .button:hover,
.inside-page-row a.primary:hover,
.homeboxes a.primary:hover,
.primary:hover,
.inside-page-row button[type="submit"]:hover,
.inside-page-row .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
.ui-form-buttons .ui-form-button button:hover,
.module-search button:hover {
	color: #fff !important;
	background-color: #525252 !important;
	text-decoration: none;
}

.secondary,
.inside-page-row a.secondary,
.homeboxes a.secondary,
.inside-page-row button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-page-row .cms_form_button.secondary,
.inside-page-row .button.secondary,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger {
	display: inline-block;
	color: #fff !important;
	text-align: center;
	text-transform: capitalize;
	background-color: #797676 !important;
	padding: 0.375rem 1rem;
	font-family: Montserrat, sans-serif;
	font-size: 0.95rem;
	font-weight: 600;
	text-decoration: none;
	transition: background-color 0.2s, color 0.2s;
	border: 0;
	line-height: inherit;
	cursor: pointer;
	border-radius: 0;
}

.secondary:hover,
.inside-page-row a.secondary:hover,
.inside-page-row button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-page-row .cms_form_button.secondary:hover,
.inside-page-row .button:hover,
.homeboxes a.secondary:hover,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger:hover {
	background-color: #525252 !important;
	color: #fff !important;
}

.tertiary,
.inside-page-row button[type="submit"].tertiary,
.cms_form_button.tertiary,
.inside-page-row a.tertiary,
.homeboxes a.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	display: inline-block;
	border: 0;
	text-decoration: none;
	cursor: pointer;
	border-radius: 0;
	padding: 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	background-color: transparent !important;
	transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	font-family: Montserrat, sans-serif;
	line-height: 1.35rem;
	font-weight: 600;
	border-bottom-style: solid;
	border-bottom-color: transparent;
	color: #3169b4 !important;
	font-size: 0.95rem;
}

.tertiary:hover,
.inside-page-row a.tertiary:hover,
.inside-page-row button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover,
.homeboxes a.tertiary:hover {
	border-bottom-color: #3169b4 !important;
	color: #3169b4 !important;
}

/* messages */
#message.success {
	background-color: #bbee94;
	border: 2px solid #48a700;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
}

#message.error {
	background-color: #ffccd0;
	border: 2px solid #ff0014;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
}

#message.success p {
	font-family: Montserrat, sans-serif;
}

#message.error p {
	font-family: Montserrat, sans-serif;
}

/* tables */
table.styled {
	margin: 0 0 1em;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid #fff;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: 600;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #02205c;
	color: white;
}

table.styled th {
	font-weight: 600;
	font-size: 0.92rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #797676;
	color: white;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	background-color: transparent;
	transition: none;
}

table.styled th a:hover {
	color: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: lightgray;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
.inside-page-row input[type="text"],
.inside-page-row input[type="tel"],
.inside-page-row input[type="password"],
.inside-page-row input[type="email"],
.inside-page-row select,
.inside-page-row textarea,
.inside-page-row input[type="search"] {
	max-width: 100%;
	vertical-align: middle;
	padding: 8px 10px;
	border-style: solid;
	border-width: 1px;
	border-color: #666666;
	font-size: 14px;
	line-height: 1.42857143;
	color: #333333;
}

.inside-page-row textarea {
	resize: vertical;
}

.inside-page-row label {
	font-weight: 400;
}

@media (max-width: 991px) {
	.inside-page-row input[type="text"],
	.inside-page-row input[type="tel"],
	.inside-page-row input[type="password"],
	.inside-page-row input[type="email"],
	.inside-page-row select,
	.inside-page-row textarea,
	.inside-page-row input[type="search"] {
		width: 100%;
	}
}

/*blockquotes*/
blockquote p {
	font-weight: inherit;
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.875em;
	line-height: 1.25em;
}

.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	font-weight: 700;
	text-transform: uppercase;
	border-bottom: 1px solid transparent;
	font-family: Roboto, sans-serif;
	color: #394486;
	font-style: normal;
}

/*********************** Footer **********************/
/*****************************************************/
#GRIPFooterLogo {
	line-height: 11px;
	padding-top: 0px !important;
	float: right;
}

.phone-mobile {
	display: none;
	color: #fff;
	text-align: center;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.phone-desktop {
	display: inline-block;
}

.phone-desktop:hover {
	color: #fff;
}

.footer-paragraph p {
	font-family: inherit;
	line-height: inherit;
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
}

.footer-paragraph p:first-child {
	margin-top: 0px;
}

.footer-paragraph p:last-child {
	margin-bottom: 0px;
}

.footer-paragraph a,
.footer-paragraph li,
.footer-paragraph td,
.footer-paragraph span,
.footer-paragraph h1,
.footer-paragraph h2,
.footer-paragraph h3,
.footer-paragraph h4,
.footer-paragraph h5,
.footer-paragraph h6 {
	color: #fff;
}

.back-to-top-link {
	background-color: transparent;
	padding-left: 0px;
}

@media (max-width: 991px) {
	.phone-desktop {
		display: none;
	}

	.phone-mobile {
		display: inline-block;
	}

	#GRIPFooterLogo {
		float: none;
		margin: 0 auto;
	}
}

@media (max-width: 767px) {
	#GRIPFooterLogo {
		margin-left: auto;
		margin-right: auto;
	}
}

/****************** Content Modules ****************/
/***************************************************/
/* ui form tags */
.ui-form-container {
	border: 0px;
}

.ui-form-container .ui-form-legend {
	background: #fff;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #333;
	font-size: 1rem;
	line-height: 1.3rem;
	font-weight: bold;
	text-transform: none;
	padding: 0px;
	border-bottom: 0px;
}

.ui-form-fieldset .ui-form-legend {
	text-transform: none;
}

.ui-form-container .ui-form-fields {
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields .ui-form-field.required .ui-form-label em,
.ui-form-container .required_information em {
	color: #ee0000;
	font-family: inherit;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices {
	border: 1px solid #cccccc;
	border-radius: 0px;
	padding: 8px 12px;
	box-shadow: none;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
	padding: 0px;
}

.ui-form-container .ui-form-fields .select2-container .select2-choice {
	box-shadow: none;
	border-radius: 0px;
	background: none;
	border: 1px solid #ccc;
	height: 38px !important;
}

.ui-form-buttons {
	padding: 0px;
	width: 100%;
	text-align: center;
}

.ui-form-container .ui-form-fields .ui-form-error em {
	color: #ee0000;
	display: block;
	font-size: 0.9rem;
	font-style: normal;
	line-height: normal;
	font-weight: 500;
	margin: 0px 0px 0px 0px;
}

.ui-element-with-example .source-code {
	overflow: hidden;
}

@media (max-width: 991px) {
	.ui-form-container .ui-form-fields {
		padding: 0px;
	}

	.ui-form-container .ui-form-label {
		position: static;
		padding: 0px;
		height: auto !important;
		display: block;
		width: 100%;
		left: 0px;
		text-align: left;
		top: auto;
		line-height: inherit;
	}

	.ui-form-container .ui-form-input {
		width: 100%;
	}

	.ui-form-container .ui-form-input input[type="text"],
	.ui-form-container .ui-form-input input[type="tel"],
	.ui-form-container .ui-form-input input[type="password"],
	.ui-form-container .ui-form-input input[type="email"],
	.ui-form-container .ui-form-input input[type="file"],
	.ui-form-container .ui-form-input select,
	.ui-form-container .ui-form-input textarea,
	.ui-form-container .ui-form-fields .select2-container,
	.ui-form-container .ui-form-fields .select2-container-multi,
	.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
		width: 100% !important;
		max-width: 100%;
	}
}

@media (max-width: 479px) {
	.ui-form-buttons button {
		margin-bottom: 10px;
	}
}

#ui-datepicker-div {
	font-size: 0.9rem;
}

/* search */
.search-collection > .search-node {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

@media (max-width: 991px) {
	.module-search button {
		margin-top: 10px;
	}
}

/* accounts */
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 243px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	margin: 20px 0;
	padding-bottom: 4px;
	color: #333333;
	font-weight: bold;
}

#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ee0000;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url('/core/public/shared/assets/images/required.gif');
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: 100%;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/* submission forms */
#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 2px;
	font-weight: normal;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

/* services */
#services_module.list .list_item .title h3 {
	margin-top: 0px;
}

#services_module.entity .image {
	float: none;
	margin: 0px 0px 10px 0px;
}

#services_module.list .list_item .footer {
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	padding-bottom: 0px;
	margin-top: 0px;
}

#services_module.list .list_item {
	margin-bottom: 1.5rem;
	margin-top: 0.5rem;
}

/* faqs */
#faqs_module .cms_title h3 {
	margin-top: 0px;
}

#faqs_module .cms_list_item ul {
	padding-left: 0px;
	margin: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list .cms_item_divider,
#faqs_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

/* news */
#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

#news_module.cms_list .description {
	word-wrap: break-word;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
		margin-bottom: 15px;
		margin-top: 15px;
	}
}

/* partners */
#partners_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
}

#partners_module.cms_list .cms_list_item table td.image {
	padding-right: 25px;
}

#partners_module.cms_list .cms_list_item table td.image a img {
	max-width: 150px;
}

#partners_module.cms_entity .image {
	float: none;
	margin-right: 0px;
}

#partners_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

@media (max-width: 991px) {
	#partners_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#partners_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* videos */
#video_module.cms_list .cms_list_item {
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	padding-bottom: 1.5rem;
	margin-bottom: 1.5rem;
}

#video_module.cms_list_carousel .video-container .video-file-youtube iframe,
#video_module.cms_list_carousel .video-container .video-file .cms_media_container,
#video_module.cms_list_carousel .video-container .video-file video {
	background-color: #000;
	font-family: inherit;
	color: #fff;
}

#video_module.cms_list_carousel .video-container .owl-carousel .owl-nav .owl-prev,
#video_module.cms_list_carousel .video-container .owl-carousel .owl-nav .owl-next,
#video_module.cms_list_carousel .video-container .owl-carousel .owl-nav .owl-prev span,
#video_module.cms_list_carousel .video-container .owl-carousel .owl-nav .owl-next span {
	background-color: #fff;
	font-family: inherit;
	color: #000;
}

/****************** Content Boxes ******************/
/***************************************************/
/* submission forms */
#submissionforms_module.homepage_contentbox {
	text-align: left;
	line-height: 1.5rem;
	font-size: 1rem;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}

/* video */
.homepage-featured-video iframe {
	max-width: 100%;
}

/* news */
#news_module.homepage_contentbox .cms_title h3 a {
	margin-top: 0;
	margin-bottom: 0.25rem;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.5rem;
	color: #02205c;
	border-bottom: 1px solid silver;
	font-family: Montserrat, sans-serif;
	transition: all 0.2s;
}

#news_module.homepage_contentbox .cms_title h3 a:hover {
	color: #3169b4;
	border-bottom-color: rgba(0, 0, 0, 0);
	text-decoration: none;
}

#news_module.homepage_contentbox .cms_date h3 {
	color: #474848;
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	font-family: Montserrat, sans-serif;
	font-size: 0.8rem;
	font-weight: 400;
	line-height: 1.1rem;
}

.cbtext #news_module.homepage_contentbox .cms_description {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 4; /* number of lines to show */
	line-clamp: 4;
	-webkit-box-orient: vertical;
}

.cbtext #news_module.homepage_contentbox p {
	margin-bottom: 1rem;
	font-family: Montserrat, sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
	word-wrap: break-word;
}

.cbtext #news_module.homepage_contentbox > a:last-child {
	display: none;
}

@media screen and (max-width: 991px) {
	#news_module.homepage_contentbox .cms_title h3 a {
		font-size: 0.95rem;
		line-height: 1.45rem;
	}

	.cbtext #news_module.homepage_contentbox p {
		font-size: 0.95rem;
		line-height: 1.45rem;
	}
}

@media screen and (max-width: 767px) {
	#news_module.homepage_contentbox .cms_title h3 a {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.cbtext #news_module.homepage_contentbox p {
		font-size: 1rem;
		line-height: 1.5rem;
	}
}


/* partners */
#partners_module.homepage-content-box .cycle-carousel-wrap {
	display: flex;
	align-items: center;
	justify-content: center;
}

#partners_module.homepage-content-box .cycle-slideshow-carousel .partner-carousel-link {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80px;
}

#partners_module.homepage-content-box .cycle-slideshow-carousel .partner-carousel-image {
	max-height: 80px;
}

#partners_module.homepage-content-box .partners-arrow-div {
	top: 5px;
}
